<template>
  <div class="wrapper">
    <!-- 页面头部部分 -->
    <div class="header">
      <!-- 水平一级菜单 -->
      <div class="header_t">
        <el-menu :default-active="toIndex()" @select="handleSelect">
          <template v-for="item in items">
            <el-menu-item :index="item.index" :key="item.index">
              <template slot="title">
                <div class="each_first">
                  <div class="menu-img">
                    <img :src="item.url" alt="" />
                  </div>
                  <div class="nav-wo">{{ item.title }}</div>
                </div>
              </template>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>

    <!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // isSelect:1,
      items: [
        {
          index: "member1",
          title: "工作台",
          url: require("../../assets/img/huiyuanzhongxin/1.png"),
        },
        {
          index: "member2",
          title: "创新评估",
          url: require("../../assets/img/huiyuanzhongxin/2.png"),
        },
        {
          index: "member3",
          title: "找技术",
          url: require("../../assets/img/huiyuanzhongxin/3.png"),
        },
        {
          index: "member4",
          title: "找需求",
          url: require("../../assets/img/huiyuanzhongxin/4.png"),
        },
        {
          index: "member5",
          title: "找政策",
          url: require("../../assets/img/huiyuanzhongxin/5.png"),
        },
        {
          index: "member6",
          title: "找服务",
          url: require("../../assets/img/huiyuanzhongxin/6.png"),
        },
        {
          index: "member7",
          title: "找人才",
          url: require("../../assets/img/huiyuanzhongxin/7.png"),
        },
        {
          index: "member8",
          title: "科创活动",
          url: require("../../assets/img/huiyuanzhongxin/8.png"),
        },
      ],
    };
  },
  computed: {
    username() {
      let username = localStorage.getItem("ms_username");
      return username ? username : this.name;
    },
  },
  methods: {
    // 根据路径绑定到对应的一级菜单，防止页面刷新重新跳回第一个
    toIndex() {
      return this.$route.path.split("/")[1];
    },
    // 切换菜单栏
    handleSelect(index) {
      this.$router.push("/" + index);
      // $(".each_first").append("<style>.each_first::before{}</style>"); 
    },
   
     
  },
};
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  height: 95vh;
  background: #f0f0f0;
}
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  .header_t {
    float: left;
    // padding-top: 3rem;
    padding-top: 30px;
   height: 95.5vh;
    background: #5184e0;
    width: 88px;
    // width: 5rem;
    overflow-y: auto;
   }
  //  滚动条隐藏
  .header_t::-webkit-scrollbar {
    display: none;
  }
  
}

.each_first {
  /* padding: 18px 0 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  // margin-top: 2rem;
  margin-top: 32px;
}
.el-menu-item:nth-child(1) .each_first {
   margin-top: 57px;
}
.is-active::before {
  content: "";
  display: block;
  position: absolute;
  top: 57%;
  margin-top: -5px;
  right: -4px;
  height: 10px;
  width: 10px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /* display: none; */
}
.menu-img {
  // width: 4rem;
  // height: 4rem;
   width: 60px;
  height: 60px;
  border-radius: 50%;
  // line-height: 6rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  background: #2a71d2;
}
img {
  // width: 3.5rem;
  // height: 3rem;
   width: 50px;
  height: 35px;
}
.nav-wo {
  // margin-top: 1rem;
  margin-top: 10px;
}
/* --------------- 水平一级菜单栏的样式--------------------- */
.el-menu {
  border-right: 0;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #5184e0;
}
.el-menu-item {
  height: 70px;
  line-height: 15px;
  padding: 0 !important;
  color: #fff;
}
.el-menu-item.is-active {
  color: #fff;
  background-color: #5184e0;
  .menu-img {
    background-color: #fff;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
  float: left;
  margin-left: 50px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  background-color: #5184e0;
  font-weight: 700;
}
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #5184e0;
}
.el-menu--horizontal > .el-menu-item {
  // font-size: 16px;
  margin: 0 15px;
  background-color: #5184e0;
  color: #fff;
}
.el-main {
  padding: 0;
}
</style>