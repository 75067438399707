<template>
  <div>
    <div class="nav-bar">
      <div class="nav-top">
        <p>
          <span @click="dian_index" class="home">科汇云首页</span>
          <span><i class="el-icon-phone"></i></span>
          <span>咨询热线:028-62556355</span>
        </p>
        <div class="top-ri">
        <div class="ri-le">
          <span><i class="el-icon-user"></i></span>
          <span>
            <p v-if="phone">
              {{ phone }}
            </p>
            <p v-else @click="login">登录/注册</p>
          </span>
        </div>
        <div class="ri-le">
          <span>
            <p
              @mouseover="changeActive($event)"
              @mouseout="removeActive($event)"
              @click="changeActive($event)"
            >
              身份 <i class="el-icon-arrow-down"></i>
            </p>
          </span>
          <div
            class="identity"
            v-show="show"
            @mouseover="changeActive($event)"
            @mouseout="removeActive($event)"
          >
            <p @click="toExpert(info.expert)">
              {{ info.expert ? "专家" : "" }}
            </p>
            <p @click="toManager(info.manager)">
              {{ info.manager ? "技术经理人" : "" }}
            </p>
            <p @click="toProvider(info.provider.id)">
              {{ info.provider ? "服务商" : "" }}
            </p>
          </div>
        </div>
          <div class="ri-mid" @click="loginout">
            <p style="color: #3892eb">退出</p>
          </div>
        </div>
      </div>
    </div>
    <ConLeft></ConLeft>
  </div>
</template>

<script>
import { Info } from "@/common/js/api";

import ConLeft from "../../components/common/Con_left";
// import memberNe from "./base/memberNei";
export default {
  name: "member",
  data() {
    return {
      phone: "",
      id: "",
      show: false,
      info: [],
    };
  },
  created() {
	  if(!localStorage.getItem("user")){
	  		  this.$router.push({
	  		    path: "/login",
	  		  });
	  }
    this.phone = JSON.parse(localStorage.getItem("user")).phone;
    this.id = JSON.parse(localStorage.getItem("user")).id;
    this.Info();
  },
  methods: {
    Info() {
      Info({
        id: this.id,
      })
        .then((res) => {
          this.info = res.data;
        })
        .catch(() => {});
    },
    changeActive($event) {
      this.show = true;
    },
    removeActive($event) {
      this.show = false;
    },
        // 前往专家详情
    toExpert(id) {
      this.$router.push({
        path: "/expert",
        query: {
          id: id,
        },
      });
    },
     // 技术经理人详情
    toManager(id) {
      this.$router.push({
        path: "/manager",
        query: {
          id: id,
        },
      });
    },
     // 进入服务商主页
    toProvider(id) {
      this.$router.push({
        path: "/serviceListHome",
        query: {
          id: id,
        },
      });
    },
    // 首页
    dian_index() {
      this.$router.push("/home");
    },
    // 退出
    loginout() {
      this.$router.push("/home");
      localStorage.removeItem("user");
      window.localStorage.removeItem("token");
    },
  },

  components: {
    ConLeft,
    // memberNe
  },
};
</script>

<style scoped lang="less">
.nav-bar {
  .nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    padding: 0 14%;
    background-color: #f9f9ff;
    color: #696969;
    font-size: 14px;
    .home {
      cursor: pointer;
      margin-right: 30px;
    }
    .top-ri {
      display: flex;
      height: 42px;
      line-height: 42px;
      .ri-le {
        display: flex;
        cursor: pointer;
        position: relative;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          margin-left: 9px;
          margin-right: 12px;
        }
        .identity {
          position: absolute;
          top: 30px;
          left: 0px;
          background-color: #eee;
          width: 150%;
          line-height: 30px;
          z-index: 99;
        }
      }
      .ri-mid {
        display: flex;
        cursor: pointer;
        img {
          width: 11px;
          height: 14px;
        }
        p {
          margin-left: 9px;
          margin-right: 12px;
        }
      }
      .ri-ri {
        display: flex;
        cursor: pointer;
        img {
          width: 16px;
          height: 14px;
        }
        p {
          margin-left: 9px;
        }
      }
      .ri-p {
        width: 2px;
        height: 16px;
        margin-right: 20px;
        background-color: #fff;
      }
    }
  }
  .nav-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
    padding: 0 14%;
    background-color: #f9efde;
    .bot-le {
      display: flex;
      align-items: center;
      .le-le {
        width: 90px;
        height: 90px;
      }
      .le-mid {
        width: 180px;
        height: 42px;
        margin-left: 4px;
      }
      .le-ri {
        width: 205px;
        height: 44px;
        margin-left: 24px;
      }
    }
    .bot-ri {
      display: flex;
      align-items: center;
      .ri-le {
        display: flex;
        align-items: center;
        width: 200px;
        height: 36px;
        background-color: #fff;
        img {
          width: 21px;
          height: 21px;
          margin-left: 11px;
        }
        input {
          width: 145px;
          margin-left: 8px;
          margin-bottom: 1px;
          font-size: 16px;
        }
        input::-webkit-input-placeholder {
          color: #e5e5e5;
          font-size: 14px;
        }
      }
      .ri-ri {
        width: 85px;
        height: 36px;
        margin-left: 4px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        background-color: #8f0000;
        cursor: pointer;
      }
    }
  }
}
</style>
